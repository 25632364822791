import React, { useState } from "react";
import { useDateValidator } from "src/modules/hooks/useDateValidator";
import "./Input.css";
import PasswordInput from "./InputTypes/PasswordInput";
import UrlInput from "./InputTypes/UrlInput";
import InputLabel from "./InputLabel";
import CheckboxInput from "./InputTypes/CheckboxInput";

const Input = ({
  type = "text",
  placeholder = "text",
  onChange,
  value,
  disabled,
  label,
  styleInputContainer,
  unit,
  onBlur,
  defaultValue,
  tooltip,
  autocomplete,
  ...props
}) => {
  const [onFocusValue, setOnFocusValue] = useState("");
  const { validateDate } = useDateValidator();

  // Utility function for input validation
  const validateInput = (type, e) => {
    switch (type) {
      case "number":
        return (
          (e.target.type !== "number" || !e.target.validity.badInput) &&
          !isNaN(e.target.value)
        );
      case "date":
      case "datetime-local":
      case "date-short":
      case "date-medium":
        return !validateDate(e.target.value) && e.target.validity.badInput;
      default:
        return true;
    }
  };

  // Utility function to handle input validation styling
  const applyValidationStyling = (inputElement, isValid) => {
    if (!isValid) {
      inputElement.style.backgroundColor = "var(--error-color)";
      inputElement.style.borderColor = "var(--error-color)";
      inputElement.focus();
      return false;
    }

    inputElement.style.backgroundColor = null;
    inputElement.style.borderColor = null;
    return true;
  };

  const handleInputValidation = (type, e) => {
    const isValid = validateInput(type, e);
    const validationResult = applyValidationStyling(e.target, isValid);

    if (!validationResult) return false;

    return true;
  };

  const allOk = (e) => {
    if (type === "number") return handleInputValidation(type, e);
    else if (type.includes("date")) return handleInputValidation(type, e);
    return true;
  };

  const onFocus = (e) => {
    if (!onBlur) return;
    setOnFocusValue(e.target.value);
  };

  const handleOnChange = (e) => {
    if (!onChange) return;
    if (!allOk(e)) return;
    onChange(e);
  };

  const handleOnBlur = (e) => {
    if (!allOk(e) || !onBlur || onFocusValue === e.target.value) return;
    onBlur(e);
  };

  const getInputProps = () => {
    let workingValue = value !== undefined ? value : defaultValue;
    let myType = type;
    let styleOverride = {};

    if (type === "number") {
      if (isNaN(workingValue)) {
        myType = "text";
        styleOverride = { background: "var(--warning-color)" };
      }
    }

    if (type.includes("date")) {
      const validatedDate = validateDate(workingValue);
      if (!validatedDate && workingValue?.trim().length > 0) {
        myType = "text";
        styleOverride = { background: "var(--warning-color)" };
      } else {
        workingValue = validatedDate
          ? validatedDate.formatted[type]
          : workingValue;
      }
    }

    let checked = props.checked;
    if (checked === "true") checked = true;
    else if (checked === "false") checked = false;

    const baseProps = {
      name: props.name,
      autoComplete: autocomplete ?? "",
      placeholder,
      onChange: handleOnChange,
      className: type ? `input-${type}` : "input-text",
      onBlur: handleOnBlur,
      onFocus: onFocus,
      disabled: disabled ? "disabled" : "",
      style: { ...props.style, ...styleOverride },
      value: value ? workingValue : undefined,
      defaultValue: defaultValue ? workingValue : undefined,
    };

    switch (type) {
      case "checkbox":
        return {
          ...baseProps,
          type: myType,
          checked: checked || value,
          defaultChecked: defaultValue,
        };
      default:
        return {
          ...baseProps,
          type: myType || "text",
        };
    }
  };

  const renderSpecialInput = () => {
    switch (type) {
      case "password":
        return <PasswordInput {...getInputProps()} />;
      case "url":
        return <UrlInput {...getInputProps()} />;
      case "checkbox":
        return <CheckboxInput {...getInputProps()} />;
      default:
        return <input {...getInputProps()} />;
    }
  };

  return (
    <div className="input-container" style={styleInputContainer}>
      <InputLabel label={label} tooltip={tooltip} />
      {renderSpecialInput()}
      {unit && <span className="unit">{unit}</span>}
    </div>
  );
};

export default Input;
